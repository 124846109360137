<template>
  <v-app>
    <div class="login-frame">
      <div class="login-form">
        <router-link to="/" class="links">

          <v-img max-height="64" max-width="172" class="logo"
                 v-bind:src="require('@/assets/images/Leita_blue.png')">
          </v-img>
        </router-link>

        <validation-observer
            ref="observer"
            v-slot="{ invalid }"
            @change="changeColorByObserver()"
        >
          <form
              ref="form"
              method="post"
              action='https://leita.us7.list-manage.com/subscribe/post?u=6b830d25674fbb0e301217d66&amp;id=a5a4ffeefe'
          >
            <label for="email" style="padding-bottom: 12px;">E-mail *</label>
            <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
            >
              <v-text-field
                  v-model="email"
                  :error-messages="errors"
                  required
                  class="v-text-field-custom"
                  tile
                  solo
                  placeholder="myemail@gmail.com"
                  name="EMAIL"
                  id="mce-EMAIL"
              ></v-text-field>
            </validation-provider>

            <label for="email" style="padding-bottom: 12px;">Full Name *</label>
            <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required"
            >
              <v-text-field
                  v-model="full_name"
                  :error-messages="errors"
                  required
                  class="v-text-field-custom"
                  tile
                  solo
                  placeholder="Full Name"
                  name="FNAME"
                  id="mce-FNAME"
              ></v-text-field>
            </validation-provider>
            <v-col style="padding: 0px; padding-bottom: 20px;">
              <v-btn
                  tile
                  :color="submitDisableButtonColor"
                  style="width: 100%;"
                  x-large
                  :disabled="invalid"
                  type="submit"
              >
                <span class="login-button-text"> Subscribe</span>
              </v-btn>
            </v-col>

          </form>
        </validation-observer>



      </div>
    </div>
    <Footer></Footer>
  </v-app>
<!--  <div id="mc_embed_signup">-->
<!--    <form action="https://leita.us7.list-manage.com/subscribe/post?u=6b830d25674fbb0e301217d66&amp;id=a5a4ffeefe" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>-->
<!--      <div id="mc_embed_signup_scroll">-->
<!--        <h2>Subscribe</h2>-->
<!--        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>-->
<!--        <div class="mc-field-group">-->
<!--          <label for="mce-EMAIL">Email  <span class="asterisk">*</span>-->
<!--          </label>-->
<!--          <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">-->
<!--        </div>-->
<!--        <div class="mc-field-group">-->
<!--          <label for="mce-FNAME">Full Name  <span class="asterisk">*</span>-->
<!--          </label>-->
<!--          <input type="text" value="" name="FNAME" class="required" id="mce-FNAME">-->
<!--        </div>-->
<!--        <div id="mce-responses" class="clear">-->
<!--          <div class="response" id="mce-error-response" style="display:none"></div>-->
<!--          <div class="response" id="mce-success-response" style="display:none"></div>-->
<!--        </div>    &lt;!&ndash; real people should not fill this in and expect good things - do not remove this or risk form bot signups&ndash;&gt;-->
<!--        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_6b830d25674fbb0e301217d66_a5a4ffeefe" tabindex="-1" value=""></div>-->
<!--        <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->

</template>
<script>

import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required} from "vee-validate/dist/rules";
import LoginSubmitButtonMixin from "@/mixins/LoginSubmitButtonMixin";
import Footer from "@/components/home/Footer";

extend('email', {
  ...email,
  message: 'Email must be valid',
});

// Add the required rule
extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: "Subscribe",
  mixins: [LoginSubmitButtonMixin],
  components: {
    Footer,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    email: '',
    full_name: '',
  }),
}
</script>
<style scoped src="@/assets/css/login.css">
</style>